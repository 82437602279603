<template>
  <div>
    <!-- 角色管理页面 -->
    <ch-layout button page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:button>
        <!--          :style="{ display: treeSelect.roleadd ? 'inline-block' : 'none' }"-->
        <ch-button
          @click="openAdd"
        >新增</ch-button>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  size="mini" height="100%">
          <template v-slot:handle="scope">
            <ch-button type="link"
                       @click="openEdit(scope.row)"
            >编辑</ch-button>
            <ch-button type="link"
                       @click="openWatch(scope.row)"
            >查看</ch-button>
            <ch-button type="delete"
                       @click="handleDelete(scope.row)"
            >删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page type="old" ref="page"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 查询 编辑 修改 -->
    <role-modal ref="modal"
      :getListFunction="getTableList"
    ></role-modal>
  </div>
</template>
<script>
import { getTreeSelect, handleDelete } from "@/utils/utils";
import RoleModal from "./roleModal";
import {mapActions, mapGetters} from "vuex";
export default {
  components: { RoleModal },
  data() {
    return {
      tableList: [],
      isTableLoading: false,
      treeSelect: {
        roleremove: true, //删除
        roleedit: true, //编辑
        rolequery: true, //查看
        roleadd: true, //新增
      },
      // 搜索相关
      searchForm: {
        name: null,
      },
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '角色名称', prop: 'roleName', showOverflowTooltip: true },
        // {column: 'text', label: '排序', prop: 'roleSort', showOverflowTooltip: true},
        {column: 'slot', label: '操作', slotName: 'handle', width: '120px', fixed: 'right' }
      ]
    },
    searchOption: function () {
      return [
        {type: 'input', label: '角色名称', prop: 'name', placeholder: "角色名称" },

        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    ...mapGetters(['userInfo'])
  },
  created() {
    // 获取操作显示权限
    getTreeSelect(this.$route.name).then((res) => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    ...mapActions({
      getRoleOption: 'system/getRoleOption'
    }),
    // 查询
    searchHandle() {
      this.getTableList(this.searchForm)
    },
    // 列表数据
    getTableList(searchForm=this.searchForm) {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/role/list', searchForm).then(res => {
        let rows = res.rows;
        this.tableList = rows;
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 打开查看
    openWatch(row) {
      this.$refs.modal?.openWatch(row)
    },
    // 打开增加
    openAdd(){
      this.$refs.modal?.openAdd()
    },
    // 打开修改
    openEdit(row) {
      this.$refs.modal?.openEdit(row)
    },
    // 删除
    handleDelete(row) {
      handleDelete("/hm/role/", row.id).then(() => {
        this.getTableList()
        this.getRoleOption(true)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
